<template>
	<div>
		<transition name="fade">
			<Message v-if="error" :message="error" type="error" />
		</transition>

		<h4>Are you sure you want to logout?</h4>

		<SubmitButton type="important" @click="logout">
			Yes, sign me out
		</SubmitButton>

		<SubmitButton @click="decline">
			No, stay signed in
		</SubmitButton>
	</div>
</template>

<script>
import api from "@/api";

import SubmitButton	from "@/components/SubmitButton.vue";
import Message		from "@/components/Message.vue";

export default {
	name: "Logout",

	components: {
		SubmitButton,
		Message
	},

	data () {
		return {
			error: undefined
		};
	},

	methods: {
		decline () {
			this.$router.push({name: "Home"});
		},

		async logout () {
			try {
				await api.logout();

				this.$router.push({name: "Home"});
			} catch (e) {
				console.error(e);

				if (e.errcode)
					return this.error = e.data.error;

				this.error = e.message ? e.message : e;
			}
		}
	}
};
</script>

<style scoped>
h4 {
	text-align: center;
}
</style>
